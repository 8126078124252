import omit from 'lodash/omit';

// turns logic within className into a string to make sure no 'undefined' etc. show up in the DOM
type Classes = string | undefined | false | null;
export const cx = (...classes: Classes[]) => classes.filter(Boolean).join(' ');

export const removeKeysFromObject = <T extends object, K extends keyof T>(
    obj: T,
    ...keysToRemove: K[]
): Omit<T, K> => omit(obj, keysToRemove) as Omit<T, K>;

export const removeKeysFromArrayObjects = <T extends object, K extends keyof T>(
    array: T[],
    ...keysToRemove: K[]
): Omit<T, K>[] =>
    array.map((obj) => removeKeysFromObject(obj, ...keysToRemove));

// example: const { cancel } = await waitAndExecute(1000, refetchDocuments);
export async function waitThenHandle<T>(
    delay: number,
    callback: () => Promise<T>
): Promise<{ cancel: () => void }> {
    let timeoutId: ReturnType<typeof setTimeout>;
    let isCancelled = false;

    const cancel = () => {
        if (timeoutId !== undefined) {
            clearTimeout(timeoutId);
        }
        isCancelled = true;
    };

    await new Promise((resolve) => {
        timeoutId = setTimeout(resolve, delay);
    });

    if (!isCancelled) {
        await callback();
    }

    return { cancel };
}

export const bytesToMB = (bytes: number) => {
    const mb = bytes / 1048576; // 1048576 in 1 mb

    // returns either whole number or 2 numbers after decimal
    return mb % 1 === 0 ? mb : parseFloat(mb.toFixed(2));
};

export const MBToBytes = (mb: number) => mb * 1024 * 1024;
