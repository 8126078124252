/* theme.ts */
import { theme } from '@chakra-ui/pro-theme';
import { extendTheme } from '@chakra-ui/react';
import '@fontsource-variable/inter';

const proTheme = extendTheme(theme);
const extensions = {
    colors: { ...proTheme.colors, brand: proTheme.colors.teal },
    fonts: {
        heading: "'Inter Variable', -apple-system, system-ui, sans-serif",
        body: "'Inter Variable', -apple-system, system-ui, sans-serif",
    },
};
export const connectTheme = extendTheme(extensions, proTheme);
