import { useBreadcrumb } from '@common/context/breadcrumb-context';
import {
    Box,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbSeparator,
} from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';

const CaretSeparator = () => (
    <Box as="span" display="flex" alignItems="center">
        <ChevronRightIcon w="21px" h="19px" />
    </Box>
);

export const BreadcrumbNavigation = () => {
    const { breadcrumbs } = useBreadcrumb();

    return (
        <Breadcrumb separator={<CaretSeparator />} spacing="0">
            {breadcrumbs.map((breadcrumb, index) => {
                const isOnlyBreadcrumb = breadcrumbs.length === 1;

                return (
                    <BreadcrumbItem key={index}>
                        <BreadcrumbLink href={breadcrumb.href}>
                            {breadcrumb.label}
                        </BreadcrumbLink>
                        {isOnlyBreadcrumb ? <BreadcrumbSeparator /> : null}
                    </BreadcrumbItem>
                );
            })}
        </Breadcrumb>
    );
};
