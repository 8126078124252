'use client';

import * as React from 'react';
import { Session } from 'next-auth';
import { SessionProvider, getSession } from 'next-auth/react';
import {
    ApolloProvider,
    ApolloClient,
    InMemoryCache,
    ApolloLink,
    NormalizedCacheObject,
    fromPromise,
} from '@apollo/client';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ChakraProvider } from '@chakra-ui/react';
import NextTopLoader from 'nextjs-toploader';
import { Toaster } from 'react-hot-toast';
import { Tooltip } from 'react-tooltip';
import config from '@root/config';
import { connectTheme } from '@app/theme';
import { SDKProvider } from '@connect-core/connect-sdk';
import { LayoutAppShell } from '@app/LayoutAppShell';
import { BreadcrumbProvider } from '@common/context/breadcrumb-context';

const uploadLink = createUploadLink({
    uri: 'https://dev-mark-core-api.connect-ai.dev/graphql',
    headers: { 'Apollo-Require-Preflight': 'true' },
});

const authLink = new ApolloLink((operation, forward) =>
    fromPromise(
        getSession().then((session: Session | null) => {
            console.log('session', session);
            const token = session?.user?.token
                ? `Bearer ${session.user.token}`
                : '';
            operation.setContext({
                headers: {
                    ...operation.getContext().headers,
                    authorization: token,
                },
            });
        })
    ).flatMap(() => forward(operation))
);

const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
    link: ApolloLink.from([authLink, uploadLink]),
    cache: new InMemoryCache(),
});

// All the client wrappers are here (they can't be in server components)
// need to update these comments
// 1. SessionProvider: Allow the useSession from next-auth (find out if user is auth or not)
// 2. ApolloProvider: Allow the use of hooks from apollo-client
// 3. NextTopLoader: Show a progress bar at the top when navigating between pages
// 4. Toaster: Show Success/Error messages anywhere from the app with toast()
// 5. Tooltip: Show tooltips if any JSX elements has these 2 attributes: data-tooltip-id="tooltip" data-tooltip-content=""
// 6. CrispChat: Set Crisp customer chat support (see above)
export const LayoutClient = ({ children }: React.PropsWithChildren) => {
    const queryClient = new QueryClient();

    return (
        <>
            <SessionProvider>
                <ApolloProvider client={client}>
                    <QueryClientProvider client={queryClient}>
                        <SDKProvider apolloClient={client}>
                            <BreadcrumbProvider>
                                <ChakraProvider theme={connectTheme}>
                                    {/* Show a progress bar at the top when navigating between pages */}
                                    <NextTopLoader
                                        color={config.colors.main}
                                        showSpinner={false}
                                    />
                                    {/* Content inside app/page.js files  */}
                                    <LayoutAppShell>{children}</LayoutAppShell>
                                    {/* Show Success/Error messages anywhere from the app with toast() */}
                                    <Toaster
                                        toastOptions={{
                                            duration: 3000,
                                        }}
                                    />

                                    {/* Show tooltips if any JSX elements has these 2 attributes: data-tooltip-id="tooltip" data-tooltip-content="" */}
                                    <Tooltip
                                        id="tooltip"
                                        className="z-[60] !opacity-100 max-w-sm shadow-lg"
                                    />
                                </ChakraProvider>
                            </BreadcrumbProvider>
                        </SDKProvider>
                    </QueryClientProvider>
                </ApolloProvider>
            </SessionProvider>
        </>
    );
};
