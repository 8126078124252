import * as React from 'react';
import Link from 'next/link';
import {
    Menu,
    MenuProps,
    MenuList,
    MenuListProps,
    MenuButton as MenuTrigger,
    MenuButtonProps as MenuTriggerProps,
    As,
    MenuItem,
    MenuItemProps,
    Portal,
} from '@chakra-ui/react';

function CtMenuRoot(props: MenuProps) {
    return <Menu isLazy {...props} />;
}

function CtMenuList(props: MenuListProps) {
    /* 
        browser warning can be ignored as any wrapper around popovers like via Chakra may cause strict semantic html warnings 
        example if used inside table: validateDOMNesting(...): <span> cannot appear as a child of <tbody>. - Chakra creates an empty span, not sure why.
        See: https://github.com/chakra-ui/chakra-ui/issues/6227
     */
    return (
        <Portal>
            <MenuList {...props} />
        </Portal>
    );
}

type CtMenuTriggerProps<C extends As> = MenuTriggerProps & {
    as: C;
} & React.ComponentProps<C>;
function CtMenuTrigger<C extends As>({
    as: Component,
    ...props
}: CtMenuTriggerProps<C>) {
    return <MenuTrigger as={Component} {...props} />;
}

function CtMenuButton(props: MenuItemProps) {
    return <MenuItem fontSize="0.875rem" fontWeight="500" {...props} />;
}

interface CtMenuLinkProps {
    href: string;
}
function CtMenuLink({ href, ...props }: MenuItemProps & CtMenuLinkProps) {
    return (
        <Link href={href || '#'}>
            <MenuItem
                as="div"
                fontSize="0.875rem"
                fontWeight="500"
                {...props}
            />
        </Link>
    );
}

export const CtMenu = Object.assign(CtMenuRoot, {
    List: CtMenuList,
    Trigger: CtMenuTrigger,
    Button: CtMenuButton,
    Link: CtMenuLink,
});
