'use client';
import * as React from 'react';
import { signOut, useSession } from 'next-auth/react';
import Link from 'next/link';
import {
    Box,
    Flex,
    HStack,
    Input,
    InputLeftElement,
    InputGroup,
    IconButton,
    Avatar,
} from '@chakra-ui/react';
import { ExternalLinkIcon, SettingsIcon } from '@chakra-ui/icons';
import { BsCaretRightFill } from 'react-icons/bs';
import { FiSearch, FiBell } from 'react-icons/fi';
import { MdDashboard } from 'react-icons/md';
import { BiTable, BiBookContent } from 'react-icons/bi';
import { PiCaretRightBold, PiCaretLeftBold } from 'react-icons/pi';
import { AiOutlineTable } from 'react-icons/ai';
import { IoDocuments } from 'react-icons/io5';
import { HiMiniUserCircle } from 'react-icons/hi2';
import { BreadcrumbNavigation } from '@common/BreadcrumbNavigation';
import { ConnectAiLogo } from '@common/Logo';
import { CtMenu } from '@common/CtMenu';
import { ROUTES } from '@app/routes';
import { cx } from '@common/utils';

export const LayoutAppShell = ({ children }: React.PropsWithChildren) => {
    const { status } = useSession();

    return (
        <Box overflow="hidden" position="relative" backgroundColor="white">
            <Flex h="full">
                {status === 'authenticated' ? (
                    <AuthenticatedApp>{children}</AuthenticatedApp>
                ) : null}
                {status === 'loading' ? <Loading /> : null}
                {status === 'unauthenticated' ? (
                    <UnauthenticatedApp>{children}</UnauthenticatedApp>
                ) : null}
            </Flex>
        </Box>
    );
};

function AuthenticatedApp({ children }: React.PropsWithChildren) {
    return (
        <>
            <Sidebar />
            <Box flex="1">
                <TopNavigation />
                <Box flex="1">{children}</Box>
            </Box>
        </>
    );
}

function TopNavigation() {
    return (
        <Flex
            py="12px"
            px="32px"
            alignItems="center"
            justifyContent="space-between"
            width="full"
        >
            <BreadcrumbNavigation />
            <Flex alignItems="center" gap="1rem">
                <InputGroup display="flex" maxWidth="272px" width="full">
                    <InputLeftElement>
                        <FiSearch size="18" />
                    </InputLeftElement>
                    <Input placeholder="Search" width="full" />
                </InputGroup>
                <IconButton
                    aria-label="Notifications"
                    title="Notifications"
                    icon={<FiBell color="#4A5568" size="24" />}
                    background="none"
                />
                <NavButton />
            </Flex>
        </Flex>
    );
}

export type NavButtonProps = {
    orgId?: string;
};
function NavButton(params: NavButtonProps) {
    const { data: session } = useSession();
    const image = session?.user?.image;

    const handleSignOut = () => {
        signOut({ callbackUrl: ROUTES.HOME });
    };

    return (
        <CtMenu>
            <CtMenu.Trigger
                as={IconButton}
                aria-label="Profile"
                title="Profile"
                background="transparent"
                icon={
                    <Avatar
                        src={image || undefined}
                        name={session?.user?.name || 'Account'}
                        paddingTop={cx(!image && '1px')}
                        paddingLeft={cx(!image && '1px')}
                        height="40px"
                        width="40px"
                    />
                }
            />
            <CtMenu.List>
                <CtMenu.Link
                    fontSize="1rem"
                    fontWeight="400"
                    href={ROUTES.PROFILE}
                    icon={<HiMiniUserCircle />}
                >
                    My Profile
                </CtMenu.Link>
                <CtMenu.Link
                    fontSize="1rem"
                    fontWeight="400"
                    href={
                        params.orgId
                            ? ROUTES.ADMIN.ORGANIZATION(params.orgId)
                            : ROUTES.ADMIN.ROOT
                    }
                    icon={<SettingsIcon />}
                >
                    Admin
                </CtMenu.Link>
                <CtMenu.Button
                    fontSize="1rem"
                    fontWeight="400"
                    icon={<ExternalLinkIcon />}
                    onClick={handleSignOut}
                >
                    Logout
                </CtMenu.Button>
            </CtMenu.List>
        </CtMenu>
    );
}

type SidebarProps = {
    isSidebarExpanded?: boolean;
};
function Sidebar({ isSidebarExpanded = true }: SidebarProps) {
    const [isExpanded, setIsExpanded] = React.useState(isSidebarExpanded);
    const [isToggleVisible, setIsToggleVisible] = React.useState(!isExpanded);
    const expandCollapseLabel = `${isExpanded ? 'Collapse' : 'Expand'} Sidebar`;

    function toggleSidebar() {
        setIsExpanded((current) => !current);
    }

    function showToggle() {
        setIsToggleVisible(true);
    }

    function hideToggle() {
        setIsToggleVisible(!isExpanded);
    }

    return (
        <Box
            minHeight="100vh"
            minW={isExpanded ? '16.5rem' : 4}
            bg="teal.500"
            color="white"
            fontSize="sm"
            shadow="md"
            onMouseEnter={showToggle}
            onMouseLeave={hideToggle}
            style={{ position: 'relative' }}
        >
            {isToggleVisible ? (
                <IconButton
                    onClick={toggleSidebar}
                    aria-label={expandCollapseLabel}
                    title={expandCollapseLabel}
                    icon={
                        isExpanded ? <PiCaretLeftBold /> : <PiCaretRightBold />
                    }
                    size="xs"
                    borderRadius="full"
                    style={{
                        border: '1px solid #999',
                        position: 'absolute',
                        top: '50%',
                        right: '-12px',
                        transform: 'translateY(-50%)',
                        zIndex: 1000,
                    }}
                />
            ) : null}
            {isExpanded && <ExpandedSidebarContent />}
        </Box>
    );
}

function ExpandedSidebarContent() {
    return (
        <Flex h="full" direction="column" px="6" py="8" gap="2rem">
            <ConnectAiLogo />
            <Flex direction="column" flex="1" overflow="auto" gap="2rem">
                <Flex direction="column">
                    <NavItem
                        icon={
                            <MdDashboard
                                color="white"
                                title="Dashboard"
                                size={22}
                            />
                        }
                        label="Dashboard"
                        href={ROUTES.DASHBOARD}
                    />
                    <NavItem
                        icon={
                            <IoDocuments
                                color="white"
                                title="Dashboard"
                                size={22}
                            />
                        }
                        label="Customer Templates"
                        href={ROUTES.TEMPLATES.CUSTOMER}
                    />
                </Flex>
                <Flex direction="column">
                    <Flex color="gray.300" fontWeight="600">
                        Master Data
                    </Flex>
                    <NavItem
                        icon={
                            <BiTable color="white" title="Products" size={22} />
                        }
                        label="Products"
                        href={ROUTES.MASTER_DATA.PRODUCTS}
                    />
                    <NavItem
                        icon={
                            <AiOutlineTable
                                color="white"
                                title="Customers"
                                size={22}
                            />
                        }
                        label="Customers"
                        href={ROUTES.MASTER_DATA.CUSTOMERS}
                    />
                    <NavItem
                        icon={
                            <BiBookContent
                                color="white"
                                title="Data Dictionary"
                                size={22}
                            />
                        }
                        label="Data Dictionary"
                        href={ROUTES.MASTER_DATA.DATA_DICTIONARY}
                    />
                </Flex>
            </Flex>
        </Flex>
    );
}

interface NavItemProps {
    active?: boolean;
    subtle?: boolean;
    icon: React.ReactElement;
    label: string;
    endElement?: React.ReactElement;
    href?: string;
}

const NavItem = ({
    active,
    subtle,
    icon,
    children,
    label,
    endElement,
    href,
}: React.PropsWithChildren<NavItemProps>) => {
    return (
        <Link href={href || '#'}>
            <HStack
                w="full"
                p="0.625rem"
                minHeight="2.75rem"
                fontSize="0.875rem"
                cursor="pointer"
                userSelect="none"
                rounded="md"
                transition="all 0.2s"
                bg={cx(active && 'gray.700')}
                _hover={{ bg: 'gray.700' }}
                _active={{ bg: 'gray.600' }}
            >
                <Box fontSize="lg" color={active ? 'currentcolor' : 'gray.400'}>
                    {icon}
                </Box>
                <Box flex="1" fontWeight="500" color={cx(subtle && 'gray.400')}>
                    {label}
                </Box>
                {endElement && !children && <Box>{endElement}</Box>}
                {children && (
                    <Box fontSize="xs" flexShrink={0} as={BsCaretRightFill} />
                )}
            </HStack>
        </Link>
    );
};

function UnauthenticatedApp({ children }: React.PropsWithChildren) {
    return (
        <Box flex="1" p="0">
            {children}
        </Box>
    );
}

const Loading = () => <div></div>;
